.module-title-toolbar-page-store-sections {
	position: relative;
	right: 28px;
	height: 30px;
	display: grid;
	grid-template-columns: 26px;
}


.topic-list .table-row, .topic-list .table-header-row {
	display: grid;
    grid-template-columns: 140px auto 134px 40px;

}


.dialog-new-topic textarea{
	height: 240px;
}
