
.postings {
	color: var(--forum-color);
    padding: 0px 0px 0px 0px;
    line-height:normal;
}

.postings ul {
	padding-left: 14px;
	margin: 0px;
}

.thread .bi-three-dots, .answer .bi-three-dots {
	border: 1px solid #dfdfdf;
	border-radius: 4px;
	margin: 0px 0px 0px 6px;
	max-height: 20px;
}

.postings iframe {
	max-width: 120px;
	max-height: 120px;;
}

.postings .bi-pencil {
	margin: 0px 4px 0px 0px;
	color: var(--icon-color);
}

.posting {
    padding: 0px 0px 0px 0px;
    line-height:normal;
}

.posting-shrink {
	display: block;
	max-height: 162px;
}
.posting-content {
	font-style: normal;
    color: var(--forum-color);
	cursor: default;
	overflow-x: auto;
	overflow-y: hidden;
}

.posting-content-font-normal {
	font-weight: 400;
}

.posting-content-font-bold {
	font-weight: 600;
}

.posting-content a, .posting-content a:hover{
	color: var(--forum-link-color);
	font-weight: normal;
}

.posting-content-more {
	display: block;
	color: var(--forum-color);
	font-weight: bold;
	cursor: pointer;
	margin-top: 6px;
}

.posting-quote {
	display: inline-block;
	width: auto;
	border: 1px solid var(--forum-quote-color);
	border-radius: 4px;
	border-style: solid !important;
	/* border-inline-start-color: #EBECEF; */
	border-inline-start-color: var(--forum-quote-color);
	padding: 4px;
	margin: 4px 0px 4px 0px;
}

.posting-head {
    padding: 2px 0px 0px 0px;
	margin: 2px 0px;
}

.content-div {
    display: block;
	color: var(--forum-color);
	background-color: var(--forum-bg-color);

}

.thread {
    display: flex;
	margin-top: 8px;
}

.posting-title {
	font-weight: 400; 
}

.smiley, .hide-images .smiley{
    max-width: 200px;
    max-height: 42px;
	vertical-align: baseline;
	border-radius: initial;
}

.hide-images img {
	max-width: 15px;
	max-height: 15px;
}

.posting-date {
    color: #999;
	font-weight: normal;
    padding: 0px 4px 0px 4px;
}

.posting-selected {
	display: block;
	background-color: var(--forum-posting-selected);
	border: 1px solid var(--forum-posting-selected);
	border-radius: 4px;
}

.posting-unread .bi-three-dots {
	background-color: var(--main-bg-color);
	color: white;
	border-radius: 4px;
}

.posting-title {
	color: black;
	font-weight: 600;
	line-height: normal;
	margin-right: 4px;
}

.posting-topic {
	display: block;
	height: 18px;
	background-color: #efefef;
	border: 1px solid #dfdfdf;
	border-radius: 4px;
	color: #8f8f8f;
	margin: 0px 4px 0px 4px;
	padding: 0px 4px;
	cursor: pointer;
}

.thread-title {
    margin: 2px 0px 4px 0px;
	display: flex;
	align-items: baseline;

}

#dialog-answer .posting-parent {
    padding: 0px 12px 0px 8px;
	height: 100%;
	width: 100%;
	overflow: scroll;
}

.answer {
	display: block;
	margin: 2px 0px 0px 0px;
}

.answer img, .posting-content img, .postings img { 
    vertical-align: baseline !important;
}
.answer{
	display: block;
	line-height:normal;
}

.answer a {
    padding: 0px 2px 0px 6px;
    font-weight: normal;
}

.answer .title{
    font-weight: normal;
}

.answer-a a{
    color: #000000;
}

.answer-b a{
    color: #888888;
}

.member-name {
	display: inline-block;
	font-weight: bold;
	min-height: 21px;
	vertical-align: baseline;
	cursor: pointer;
}

.member-colored .member-name-admin {
	color: #0000ff;
}

.member-colored .member-name-mod {
	color: #0c8928;
}

.member-colored .member-name-user {
	color: #D3742f;
}

.member-level {
	color: #999;
	font-weight: 400;
	margin-left: 4px;
	border: 1px solid #dfdfdf;
	border-radius: 4px;
	padding: 1px 4px;
}