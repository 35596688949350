.webapp-avatar {
	display: grid;
	justify-content: left;
	cursor: pointer;
}

.webapp-avatar-xlarge {
    grid-template-columns: 132px auto;
}

.webapp-avatar-large {
    grid-template-columns: 44px auto;
}

.webapp-avatar-small {
    grid-template-columns: 24px auto;
}

.webapp-avatar-small img{
	width: 24px;
	height: 24px;
}

.webapp-avatar-large img{
	width: 36px;
	height: 36px;
}

.webapp-avatar-xlarge img{
	width: 124px;
	max-width: 124px;
	height: 124px;
	max-height: 124px;
}

.webapp-avatar-content {
	display: flex;
	justify-content: center;
	align-items: center;

}

.webapp-avatar-content-xlarge {
	margin: 0px 12px 0px 0px;
	width: 124px;
	height: 124px;
	min-width: 124px;
	min-height: 124px;
	font-size: 64px;
  }
  
  .webapp-avatar-content-large {
  margin: 3px 12px 0px 0px;
  width: 36px;
  height: 36px;
  min-width: 36px;
  min-height: 36px;
  font-size: 16px;
}

.webapp-avatar-content-small {
  padding: 4px;
  width: 24px;
  height: 24px;
  min-width: 24px;
  min-height: 24px;
  font-size: 16px;
}

.webapp-avatar .avatar-text {
	position: relative;
	background-color: transparent;
	left: 0px;
}

.webapp-avatar .avatar-name {
	display: block;
	justify-content: left;
	height: 40px;
	width: 100%;
	overflow: hidden;
	min-height: 28px;
    padding: 10px 6px;
	color: var(--listing-color);
}
