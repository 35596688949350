.dialog-add-member {
	position: fixed;
    top: 152px;
	right: 28px;
	height: auto;
	width: 320px;
	z-index: 9999;
}
.dialog-add-member #hint{
	margin-bottom: 4px;
}
.dialog-add-member #message{
	color: var(--content-color-hint);
}
.dialog-add-member #error{
	color: var(--content-color-error);
}

@media screen and (max-width:1024px) {
	.dialog-add-member {
		left: 34px;
	}
}