.dialog-change-password {
	position: fixed;
    top: 218px;
	left: 300px;
	height: auto;
	width: 420px;
	z-index: 9999;
}
.dialog-change-password #hint{
	margin-bottom: 4px;
}
.dialog-change-password #message{
	color: var(--content-color-hint);
}
.dialog-change-password #error{
	color: var(--content-color-error);
}

@media screen and (max-width:1024px) {
	.dialog-change-password {
		left: 34px;
	}
}