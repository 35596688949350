.select-text {
	width: 140px;
	margin: 0px;
	cursor: pointer;
}

.select-text .bi-three-dots {
	color: var(--main-bg-color);	
}

.select-text .input-reset {
	background-color: transparent;	
}

.select-text-label {
	margin-left: 12px;
}

.form-select-input {
	cursor: pointer;
}

.form-select-input:read-only, .form-select-input:disabled, form-select-input::placeholder {
	background-color: var(--content-bg-color)!important;
	color: var(--content-color)!important;
	opacity: 1!important;
}

.select-image {
	position: absolute;
	bottom: 102px;
	left: 16px;
	right: 16px;
	width: calc(100% - 36px);
	padding-top: 8px;
	background-color: var(--content-bg-color);
	border-radius: 0.25rem!important;
    -webkit-box-shadow: 0 1rem 3rem var(--shadow-color) !important;
    -moz-box-shadow: 0 1rem 3rem var(--shadow-color) !important;
	box-shadow: 0 1rem 3rem var(--shadow-color) !important;

	z-index: 19500;
}


.select-text-item {
	cursor: pointer;
	margin: 2px 4px;
	overflow: hidden;
}

.select-text-item:hover {
	background-color: #ced4da;
}

.select-image .image-selection {
	position: absolute;
	overflow: auto;
	top: 54px;
	left: 8px;
	right: 2px;
	height: calc(100% - 68px);
}

.select-image input, .select-image label {
	margin: 2px 7px;
	width: calc(100% - 14px) !important;;
}

.select-image .select-smiley {
	margin: 4px;
}

.select-smiley {
	max-width: 120px !important;
}
	
.select-icon {
	height: 32px;
}

