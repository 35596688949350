.member-list {
	position: fixed;
}


.member-list-parent {
	position: fixed;
	overflow: hidden;
}

.member-list {
    left: 284px;
	right: 12px;
    top: 104px;
    bottom: 14px;
	width: auto;
	height: auto;
}

.member-list .webapp-avatar {
	align-items: center;			/* Vertical */
}


.member-list .column-1, .member-list .header-column-1 {
	width: 50px;
}

.member-list .column-2, .member-list .header-column-2 {
	width: 200px;
	margin-right: 6px;
	overflow: hidden;
}

.member-list .column-3, .member-list .header-column-3, .member-list .column-4, .member-list .header-column-4 {
	width: 180px;
}

.member-list .column-5, .member-list .header-column-5, .member-list .column-6, .member-list .header-column-6 {
	width: 80px;
}

@media screen and (max-width:1024px) {
	.member-list {
		left: 6px;
		width: 400px;
	}

	.member-list .column-3, .member-list .header-column-3, .member-list .column-4, .member-list .header-column-4, .member-list .column-5, .member-list .header-column-5, .member-list .column-6, .member-list .header-column-6 {
		visibility: hidden;
		width: 0px;
	}

}

@media screen and (max-width:512px) {
	.member-list {
		left: 6px;
		width: 360px;
	}

	.member-list .column-2, .member-list .header-column-2 {
		width: 360px;
	}
	
	.member-list .column-3, .member-list .header-column-3, .member-list .column-4, .member-list .header-column-4, .member-list .column-5, .member-list .header-column-5, .member-list .column-6, .member-list .header-column-6 {
		visibility: hidden;
		width: 0px;
	}

}
