.dialog {
	position: absolute;
    background-color: var(--dialog-bg-color);
    color:var(--dialog-color);
	padding: 0px 6px;
    overflow: hidden;
	border-radius: 0.25rem!important;
	border: 1px solid var(--dialog-border-color);	
    -webkit-box-shadow: 0 1rem 3rem var(--shadow-color) !important;
    -moz-box-shadow: 0 1rem 3rem var(--shadow-color) !important;
	box-shadow: 0 1rem 3rem var(--shadow-color) !important;
	z-index: 9500;
}

.dialog input, .dialog textarea {
	background-color: var(--dialog-bg-color);
}
  
.dialog-title {
	position: relative;
	display: flex;
  	align-items: center;
    color: var(--dialog-title-color);    
    background-color: transparent;
    min-height: 40px;
	overflow: hidden;
	left: 0px;
	right: 0px;
	border-bottom: 1px solid var(--dialog-color)

}

.dialog-title {
	overflow: hidden;
	white-space: nowrap;
}

.dialog-title .title{
	display: block;
    position: relative;
	box-sizing: border-box;
	white-space: nowrap;
    top: 6px;
	right: 40px;
	left: 2px;
	padding-bottom: 4px;
	overflow: hidden;
	width: auto;
	max-width: calc(100% - 38px);
}

.dialog-posting-member .dialog-title .close-dialog{
	top: 12px;
}

.dialog-title .close-dialog{
	font-size: 20px;
    position: absolute;
    top: 6px;
    right: 2px;
}

.dialog-content {
	position: relative;
	padding: 8px;
	overflow: auto;
	max-height: calc(100% - 48px);
}

.dialog .listing-content {
	background-color: var(--dialog-bg-color) !important;
	color:var(--dialog-color) !important;
	margin: 8px 0px;
	padding-left: 8px;
	height: calc(100% - 62px);
}

.dialog .listing-basis {
	background-color: var(--dialog-bg-color) !important;
	color:var(--dialog-color) !important;
}

.dialog .listing-row {
	height: 28px;
	border: none;
}

.dialog .column {
	padding: 0px !important;
}

/*
.dialog .form-buttons {
	margin-top: 14px;
}
*/ 

.dialog .form-buttons button {
	margin: 2px 6px 0px 2px;
}	

/* OK */

.dialog-ok {
	position: absolute;
	z-index: 9999;
	width: 300px;
	height: 216px;
	top: 100px;
	right: 100px;
	margin:auto;
}

.dialog-ok #ok-text{
	height: 100px;
}

/* YesNo */

.dialog-yesno {
	position: absolute;
	z-index: 9999;
	width: 300px;
	height: 116px;
	top: 118px;
	right: 38px;
	margin:auto;
}

.div-button {
	position: relative;
	top: 10px;
	width: 100%;
	display: flex;
  	align-items: center;
  	justify-content: center;
}

.div-button button{
	margin-right: 8px;
}

