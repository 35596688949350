#login-frame {
	position:absolute;
	width: 100%;
	height: 100%;
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	color: var(--login-color);
	background-color: var(--login-bg-color);
	background-image: linear-gradient(to top, #dfdfdf, var(--login-bg-color));
}

#login-frame .dialog {
	background-color: var(--login-dialog-bg-color);
	color: var(--login-dialog-color);
	border-radius: 0.5rem!important;
	border: 1px solid var(--login-dialog-border-color);	
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
	box-shadow: 0 1rem 1.5rem var(--login-dialog-shadow-color) !important;

}

#login-frame .dialog-title {
	border-bottom: 1px solid var(--login-logo-border-color);
}

#login-frame .logo {
	background-color: var(--login-logo-bg-color);
	border-radius: 0.5rem!important;
	border: 1px solid var(--login-logo-border-color);
	padding: 14px 0px;
}	

.login input {
	border: 1px solid var(--login-logo-border-color) !important;
}

.login label {
	color: var(--login-dialog-color);	
}

.login .btn, .new-member .btn {
	color: var(--login-button-color);	
	background-color: var(--login-button-bg-color);	
}


.login {
	position: absolute;
	left: 1%;
	right: 1%;
	height: auto;
	width: 332px;
	margin: 32px auto;

}

.login .dialog-content {
	padding: 10px;
	background-color: var(--login-dialog-bg-color);
	color: var(--login-dialog-color);
	overflow: hidden;
}

.login .dialog-title, .new-member .dialog-title {
	background-color: var(--login-dialog-bg-color);
	color: var(--login-dialog-color);
}

#login form {
	margin: 14px 0px 10px 0px;
}

#login img {
	display: block;
	margin: 2px auto;
}

.login .message {
	background-color: var(--login-dialog-bg-color);
	color: var(--login-dialog-color);
}

.error-message label{
	color: #dc3545 !important;
}

.success-message label{
	color: #198754!important;
}


/* New Member Registration*/
.new-member {
	position: absolute;
	left: 1%;
	right: 1%;
	height: auto;
	width: 332px;
	margin: 32px auto;

}

