fieldset {
    padding: 0px 10px 4px 10px;
	margin: 4px 0px 2px 0px;
    border: 1px solid #cecece;
    border-top-left-radius: 0.34rem !important;
    border-top-right-radius: 0.34rem !important;
    border-bottom-left-radius: 0.34rem !important;
    border-bottom-right-radius: 0.34rem !important;
}
.webapp-form-item {
    margin: 2px ;
}

.webapp-form {
	max-width: 460px;
}
.element-posting {
	margin: 6px 2px 4px 4px;
	padding: 4px 8px;
	height: 120px;
	overflow: scroll;
	border:1px solid #eee;
}

.form-control-disabled {
	margin-left: 4px;
	border: none;
	background-color: transparent;
	color: var(--content-disabled-color);
}

.form-control-message {
	color: red;
	width: 100%;
	border: none;
	background-color: transparent;
}

.form-div {
	overflow: auto;
	background-color: var(--content-bg-color);
	color: var(--content-color);
}

textarea:disabled {
	background-color: #fcfcfc;
}