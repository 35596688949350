.dialog-get-text {
	position: absolute;
	top: 6px;
	width: 240px;
	height: 232px;
	z-index: 9999;
}

.dialog-get-text .dialog-content{
	padding: 0px;
}

.dialog-get-text .listing{

	border-radius: 0%;
	z-index: 9000;

}

.dialog-get-text .listing-header{
	display: none;
}

.dialog-select-open-window {
	left: var(--module-left);
}

.dialog-select-layout-settings {
	top: 144px;
	right: 20px;
	width: 320px;
	height: 288px;
}

.dialog-select-member-postings {
	top: 248px;
	right: 34px;
	width: 320px;
	height: 258px;
}

.select-text-label {
	margin-left: 12px;
}

