.module-member-profile {
	overflow: hidden;
}

.member-profile {
	position: absolute;
	top: 2px;
	bottom: 0px;
	right: 4px;
	height: auto;
	width: calc(100% - 4px);
}

.member-profile #memberLevel {
	width: 66px;
	overflow: visible !important;
	display: inline;
}

.member-profile fieldset {
	border: none;
	border-top: 1px solid var(--main-bg-color);
	border-radius: 0 !important;
	margin: 8px 0px 12px 0px;
}

.member-profile legend {
	padding: 0px 8px;
	font-size: 24px;
}

.member-postings-list {
	position: absolute;
	top: 38px;
	width: 100%;
	height: calc(100% - 32px);
	overflow: auto;
}

.member-postings-list .div-result{
	overflow: auto;
}

.dialog-select-member-level {
	position: relative;
	top: 94px;
	left: 46px;
	bottom: 94px;
	width: 180px;
	height: 180px;
}