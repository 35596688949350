.module-title-toolbar-smilies {
	position: relative;
	right: 28px;
	height: 30px;
	display: grid;
	grid-template-columns: 26px;
}

.smiley-list {
	position: absolute;
	top: 0px;
	bottom: 0px;
	left: 0px;
	right: 0px;
	width: auto;
	height: auto;
}

.smiley-list .listing-row, .smiley-list .listing-header-row {
	display: grid;
    grid-template-columns: 180px 180px auto;

}

.smiley-list .listing-row{
	height: auto;
	padding: 4px 0px;
}

.dialog-new-topic textarea{
	height: 240px;
}
