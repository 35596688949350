:root {
	--module-left: 280px;
}

html,
body,
#webapp,
#root {
  height: 100%;
  margin: 0;
  background-color: var(--content-bg-color);
  color: var(--content-color);
}

::placeholder {
	color: var(--placeholder-color) !important;
	opacity: 1 !important; /* Firefox */
}

button:focus,
button:active,
button:hover,
label:focus,
.btn:active,
.btn.active,
.btn:hover {
	background-color: var(--button-bg-color);
	color: var(--button-color)!important;
 	outline: 0px !important;
  	box-shadow: none !important;
}
.btn {
	background-color: var(--button-bg-color);
	color: var(--button-color);
	margin: 2px 4px;
}

.btn:hover {
	border: 1px solid var(--content-color);
}

textarea:active,
textarea:focus,
textarea:hover,
input:active,
input:focus,
input:hover,
option:focus,
option:active
{
	outline: none !important;
  	box-shadow: none !important;
}

legend {
  font-size: var(--bs-body-font-size);
  float: none!important;
  width: auto!important;
}

i {
  cursor: pointer;
}
input, textarea {
  max-width: 1200px;
  background-color: var(--content-bg-color);
}

.input-group-text {
  background-color: var(--content-bg-color);
  color: var(--content-color);
  border-color: var(--content-border-color);
}

:root {
  --desktop-size: 1000;
}

.form-control:disabled, .form-control[readonly] {
    background-color: var(--content-bg-color-disabled) !important;
    opacity:initial !important;
}

.form-control,  .form-control:focus {
	border-color: var(--content-border-color);
	background-color: var(--content-bg-color);
	color: var(--content-color);
}

.form-check-input {
	background-color: var(--content-bg-color);
	color: var(--content-color);
	border: 1px solid var(--content-border-color);
}


.form-control:focus {
	background-color: var(--content-bg-color);
	color: var(--content-color);
}

.webapp-icon {
  cursor: pointer;
  color: #fefefe;
}

.unicode-smiley {
  font-size: 24px;
}

a {
  color: #000000;
  text-decoration: none;
  font-weight:400;
}
a:hover {
  color: var(--main-bg-color);
}
img {
  max-height: 100px;
  max-width: 250px;
  border-radius: 8px;

}
label, legend, h1, h2, h3, h4 {
	color: var(--content-color);
}
.stop-scrolling {
  display: d-box;
  margin-bottom: 80px;
  height: 100%;
  overflow: hidden;
}

.no-border {
	border: none!important;
}

.no-modal {
	z-index: 99999 !important;
}

.app-icon {
	position: absolute;
	top: 12px;
	margin: 0px 4px;
	font-size: 20px;
	color: var(--nav-icon-color);

}

.bold {
    font-weight: bold;
}

.mandatory {
	border-color: var(--content-border-color);
	border-style: dashed;
}

input::-webkit-calendar-picker-indicator {
	display: none;
}
input[type="text"]::-webkit-calendar-picker-indicator,
input[type="email"]::-webkit-calendar-picker-indicator,
input[type="number"]::-webkit-calendar-picker-indicator, 
input[type="search"]::-webkit-calendar-picker-indicator {
    display: none !important;
}

i {
    font-style: normal;
}

.anker {
	cursor: pointer;
}

.bg-orange {
    --bs-bg-opacity: 1;
    background-color: #D3742f !important;
}

.blink {
	animation: animate 0.5s linear infinite; 
}

@keyframes animate{ 
	0%{ 
	  opacity: 0; 
	} 
	50%{ 
	  opacity: 0.7; 
	} 
	100%{ 
	  opacity: 0; 
	} 
} 

@media screen and (max-width:1024px) {
	:root {
		--module-left: 8px;
	}
}