.navigation-left {
	display: grid;
	width: 100%;
	height: 100%;
	cursor: pointer;
}

@media screen and (max-width:1024px) {
	.navigation-left  {
		display: none!important;
	}
}
