.dialog-add-content {
	position: fixed;
    top: 216px;
	right: 48px;
	height: auto;
	width: 522px;
	z-index: 9999;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
	box-shadow: none !important;
}

.dialog-add-content textarea {
	height: 148px;
}

@media screen and (max-width:1024px) {
	.dialog-add-content {
		left: 28px;
		width: auto;
	}
	.dialog-add-content textarea {
		height: 320px;
	}
}