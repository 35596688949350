.table-menu {
	position: fixed;
	top: 84px;
	bottom: 260px;
	right: 6px;
	left: 50%;
	width: 320px;
	overflow: hidden;
    color: #fefefe;
    border-top-left-radius: 0.34rem !important;
    border-top-right-radius: 0.34rem !important;
    border-bottom-left-radius: 0.34rem !important;
    border-bottom-right-radius: 0.34rem !important;
    -webkit-box-shadow: 0 1rem 3rem var(--shadow-color) !important;
    -moz-box-shadow: 0 1rem 3rem var(--shadow-color) !important;
	box-shadow: 0 1rem 3rem var(--shadow-color) !important;
	transform: translateX(-50%);
	z-index: 9998;
	height: calc(100% - 185px);
	max-height: 100%;

}
  
.table-menu .header-column-1 {
	width: 100%;
	display: grid;
	grid-template-columns: 20px auto 20px;
}

.table-menu .header-column-1 span{
	display: grid;
	align-items: center;
  	justify-content: center;
}


