.tabs {
	position: fixed;
	z-index: 999;
	margin-bottom: 12px;
	top: 109px;
	left: var(--module-left);
	right: 10px;
	width: auto;
	height: 26px;
	color: var(--tabs-color);
	background-color: var(--tabs-bg-color);
	border-bottom: 1px var(--tabs-border-color) solid;
}

.tabs .tab {
	position: relative;
	z-index: 999;
	padding: 4px 4px;

	border-radius: 0.25rem 0.25rem 0 0;
}

.tabs .first-tab {
	border-radius: 0.25rem 0.25rem 0 0;
}

.tabs .tab-selected {
	border-top: 1px var(--tabs-border-color) solid;
	border-left: 1px var(--tabs-border-color) solid;
	border-right: 1px var(--tabs-border-color) solid;
	background-color: var(--tabs-selected-bg-color);
}

.tabs-toolbar{
	position: fixed;
	top: 104px;
	right: 10px;
	color: var(--tabs-bg-color);
	cursor: pointer;
}



@media screen and (max-width:1024px) {
	.tabs{
		left: 8px;
	}
}