/* Allgemein */

.webapp-form, textarea {
	max-width: initial;
}

.dialog-new-posting {
	position: fixed;
	top: 116px;
	right: 26px;
	width: 50%;
	max-width: 1000px;
	max-height: 600px;
	border-radius: 0.5rem!important;
    -webkit-box-shadow: 0 1rem 3rem var(--shadow-color) !important;
    -moz-box-shadow: 0 1rem 3rem var(--shadow-color) !important;
	box-shadow: 0 1rem 3rem var(--shadow-color) !important;


}

.dialog-new-posting .btn:focus {
	background-color: #42614b;
}

.dialog-new-posting .dialog-content {
	height: 100%;
	padding: 4px 6px;
	overflow: hidden;
}

.dialog-new-posting .select-image {
	top: 58px;	
	bottom: 68px;
}

.dialog-new-posting .select-text {
	margin: 0px 0px 6px 0px;
	width: 280px;
}


.new-posting-textarea label {
	color: var(--dialog-title-color);
}

.form-new-posting .form-buttons {
	position: absolute;
	bottom: 2px;
	left: 6px;
}

.new-posting-short {
	border: var(--new-posting-short-border);
}

.show-posting-form {
    border: none;
	background-color: var(--main-bg-color);
	padding: 6px 2px 6px 4px;
	display: block;
	cursor: pointer;
}

.new-thread-short {
	position: absolute;
	background-color: transparent;
	top: 7px;
	left: 56px;
	right: 40px;
	height: 40px;
}

.new-thread-short input {
	display:inline;
	padding-left: 4px;
	max-width: none;
}

.form-new-thread-short {
	position: relative;
	width: 100%;
	margin-left: 6px;
}

/* New Thread */

.dialog-new-thread  {
	height: 460px;
}

.dialog-new-thread #posting {
	height: 312px;
}

.new-posting-textarea .form-label-group {
	display: grid;
    grid-template-columns:  auto 174px;
	margin: 4px 4px 4px 0px;
	height: 34px;
}

.dialog-new-thread .new-posting-textarea {
	height: 342px;
}


/* New Answer */

.dialog-new-answer {
    height: 538px;
}

.dialog-new-answer .form-label-group{
    height: 24px;
}

.dialog-new-answer .answer-to-textarea, #posting {
	height: 164px;
	margin: 6px 0px;
}

.dialog-new-answer .new-posting-textarea .form-label-group {
	margin: 4px 0px 0px 0px;
}

.dialog-new-answer #base-posting {
	border: 1px solid #dfdfdf;
	border-radius: 0.2rem!important;
	width: 100%;
	height: 166px;
	padding: 4px;
}

.answer-to-textarea .form-label-group{
	display: none;
}

@media screen and (max-width:720px) {
	.dialog-new-posting {
		position: absolute;
		top: 4px;
		right: 14px;
		left: 18px;
		width: auto;
	}
}