.listing-basis {
    background-color: var(--listing-bg-color);
    border: none;
    border-spacing: 1px;
    overflow: hidden;
}

.listing {
	position: absolute;
	min-width: 200px;
    background-color: var(--listing-bg-color);
    color: var(--listing-color);
    border: 1px solid var(--listing-border-color);
    border-top-left-radius: 0.34rem !important;
    border-top-right-radius: 0.34rem !important;
    border-bottom-left-radius: 0.34rem !important;
    border-bottom-right-radius: 0.34rem !important;
    border-spacing: 1px;
    overflow: hidden;
	height: calc(100% - 220px);
	z-index: 9000;
	top: 0px;
	bottom: 0px;
	left: 0px;
	right: 0px;
	width: auto;
	height: auto;
}

.listing-header {
	color: white;
	background-color: var(--listing-title-bg-color);
	padding: auto 8px;
	min-height: 34px;
	align-items: center;
	width: 100%;
}

.listing-content {
    color: var(--listing-color);
    overflow-x: hidden;
    overflow-y: auto;
	scrollbar-width: none;			/* Firefox */
    height: 100%;
}

/* WebKit */
.listing-content::-webkit-scrollbar { 
    width: 0;
    height: 0;
} 

.listing-icon, .listing .bi {
    line-height: 100%;
    font-size: 18px;
}

.listing-header-row {
    display: flex;
	color: var(--listing-title-color);    
    background-color: var(--listing-title-bg-color);
    padding: auto 8px;
    min-height: 34px;
	align-items: center;
	width: 100%;
}

.listing-header-center {
    align-items: center;
    justify-content: center;
	margin: 0px auto;
}

.listing-footer {
	color: white;
	background-color: var(--listing-bg-color);
}

.listing-close-button{
    right: 10px;
}

.listing-row {
    display: flex;
    border-bottom: 1px solid #cfcfcf;
    cursor: pointer;
	height: 38px;
}

.listing-row:hover {
	font-weight: bold;
}

.listing-row .form-check {
	display: flex;
	align-items: center;
	justify-content: center;
}


.listing-basis .column {
	display: flex;
	align-items: center;
	font-size: 14px;
	padding: 0px 0px 0px 8px;
	overflow: hidden;
	white-space: nowrap;
	-webkit-user-select: none; /* Safari */
	-ms-user-select: none; /* IE 10 and IE 11 */
	user-select: none; /* Standard syntax */
}

.listing-basis .multicolumn {
	border: none;
}

