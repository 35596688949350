.rdp {
    --rdp-cell-size: 34px;
    --rdp-caption-font-size: 20px;
}
.rdp-dropdown_month, .rdp-dropdown_year {
	color: var(--content-color);
}

.rdp-day_selected, .rdp-day_selected:focus-visible, .rdp-day_selected:hover {
	background-color: var(--content-bg-color);;
	color: var(--content-color);
}