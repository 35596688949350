.table-basis {
	position: absolute;
	min-width: 200px;
    background-color: var(--table-bg-color);
    color: var(--table-color);
    border: 1px solid var(--table-border-color);
    border-top-left-radius: 0.34rem !important;
    border-top-right-radius: 0.34rem !important;
    border-bottom-left-radius: 0.34rem !important;
    border-bottom-right-radius: 0.34rem !important;
    border-spacing: 1px;
    overflow: hidden;
	height: calc(100% - 220px);
	z-index: 9000;
	top: 0px;
	bottom: 0px;
	left: 0px;
	right: 0px;
	width: auto;
	height: auto;
}

.table-header {
	color: white;
	background-color: var(--table-title-bg-color);
	min-height: 34px;
	align-items: center;
	width: 100%;
}

.table-content {
    color: var(--table-color);
    overflow-x: hidden;
    overflow-y: auto;
	scrollbar-width: none;			/* Firefox */
    height: 100%;
}

/* WebKit */
.table-content::-webkit-scrollbar { 
    width: 0;
    height: 0;
} 

.table-icon, .table .bi {
    line-height: 100%;
    font-size: 18px;
}

.table-header-row {
	color: var(--table-title-color);    
    background-color: var(--table-title-bg-color);
}

.table-row, .table-header-row {
    display: flex;
    min-height: 34px;
	align-items: center;
	width: 100%;
}

.table-header-center {
    align-items: center;
    justify-content: center;
	margin: 0px auto;
}

.table-footer {
	color: white;
	background-color: var(--table-bg-color);
}

.table-close-button{
    right: 10px;
}

.table-row {
    border-bottom: 1px solid #cfcfcf;
    cursor: pointer;
}

.table-row:hover {
	font-weight: bold;
}

.table-row .form-check {
	display: flex;
	align-items: center;
	justify-content: center;
}


.table-basis .column {
	display: flex;
	align-items: center;
	font-size: 14px;
	padding: 0px 8px;
	overflow: hidden;
	white-space: nowrap;
	-webkit-user-select: none; /* Safari */
	-ms-user-select: none; /* IE 10 and IE 11 */
	user-select: none; /* Standard syntax */
}

.table-basis .multicolumn {
	border: none;
}

