.dialog-preview {
	position: fixed;
    top: 218px;
	right: 16px;
	height: 480px;
	width: calc(50% - 4px);
	z-index: 9999;
}

#preview-posting{
	height: 374px;
	width: calc(100% - 6px);
	overflow: scroll;
}

@media screen and (max-width:1024px) {
	.dialog-preview {
		top: 108px;
		left: 4px;
		width: calc(100% - 8px);
	}
}