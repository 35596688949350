.dialog-search-posting, .dialog-member-posting {
	position: fixed;
	top: 152px;
	right: 26px;
	bottom: 64px;
	width: 40%;
	height: auto;
}

.div-search-posting {
	overflow: hidden;
	width: 100%;
	height: auto;
}

.dialog-search-posting .dialog-content {
	height: calc(100% - 42px);
}

.div-search-posting .webapp-form {
	max-width: 100%;
}

.div-search-posting .search-element {
	width: 100%;
	padding: 4px 0px;

}

.div-search-posting .search-element span{
	padding-left: 4px;
	padding-top: 4px;
}

.div-search-posting input {
	width: 100%;
}

.dialog-search-posting .div-result {
	position: absolute;
	left: 10px;
	right: 10px;
	top: 88px;
	bottom: 0px;
	height: calc(100% - 102px);
	overflow-x: auto;

}

#new-search {
	font-size: 16px;
}

#posting-count {
	padding-left: 4px;
}
.div-status {
	margin-top: 6px;
}

.search-title {
	color: black;
	overflow: hidden;
	word-break: normal;
	white-space: nowrap;
	overflow-wrap: normal;
	font-weight: normal;
}

.search-author {
	overflow: hidden;
	font-weight: bold;
}

.search-posting {
	max-height: 120px;
	color: #777;
	overflow: hidden;
	font-weight: normal;
}

.grid-search-posting .grid-row {
	grid-template-columns: 150px auto 180px;
}

.result-row .webapp-avatar .avatar-name {
	font-size: 14px;
	color: black;
	width: 360px;
	padding: 0px 0px;
}

.grid-search-posting .title-div {
	display: flex;
	grid-template-columns: auto 28px;
}

.grid-search-posting .title-div i {
	font-size: 16px;
	color: var(--main-bg-color);
	width: 16px;
	height: 16px;
	margin-left: 8px;
}

#new-search {
	margin-top: 6px;
}

@media screen and (max-width:720px) {
	.dialog-search-posting {
		right: 12px;
		left: 12px;
		width: auto;
		background-color: white;
	}
}