.hint-frame {
	position: absolute;
	width: 356px;
	min-height: 48px;
	height: auto;
	right: 8px;
	top: 4px;
}

.hint {
	position: relative;
	width: 356px;
	height: auto;
	min-height: 48px;
	right: 0px;
	margin-top: 8px;
    background-color: var(--hint-bg-color);
    color: var(--hint-color);
    -webkit-box-shadow: 0 1rem 3rem var(--shadow-color) !important;
    -moz-box-shadow: 0 1rem 3rem var(--shadow-color) !important;
	box-shadow: 0 1rem 3rem var(--shadow-color) !important;

	padding: 0px 6px;
    overflow: visible;
	overflow-wrap: break-word;
	border-radius: 0.25rem!important;
	z-index: 9500;
}

.hint-close {
	position: absolute;
	top: 4px;
	right: 4px;
	cursor: pointer;
}

.hint-text {
	position: relative;
	padding-top: 22px;
	padding-bottom: 8px;
	left: 2px;
	width: calc(100% - 12px);
}