/* Module - Forum */
.module-title-toolbar-member {
	position: relative;
	right: 26px;
	display: grid;
    grid-template-columns: auto 26px;
}

/* Forum allgemein */
.posting-parent {
	overflow: hidden;
}
.forum {
	color: var(--forum-color);
	background-color: var(--forum-bg-color);
	position: absolute;
	display: block;
	top: 32px;
	left: 4px;
	bottom: 0px;
	width: calc(100% - 8px);
	height: calc(100% - 42px);
    font-size: 14px;
	overflow: scroll;
    padding-right: 4px;
}

.forum-postings {
    position: absolute;
    overflow-y: scroll;
    overflow-x: auto;
    white-space: normal;
    word-break: break-word;
    overflow-wrap: break-word;
}
.forum-postings-scroll {
    height: auto;
    overflow: auto;
    word-break:normal;
    white-space: nowrap; 
    overflow-wrap:normal;
}

.img-full-size {
	width: auto !important;
	height: auto !important;
	max-width: 100%;
	max-height: 100%;
	overflow:hidden;
	border-radius: 8px;
	z-index: 90000 !important;
}

.ismg-full-size {
	position: absolute;
	width: auto !important;
	height: auto !important;
	max-width: 100%;
	max-height: 100%;
	top: 50px;
	left: 0px; 
	right: 0px; 
	margin: auto; 
	overflow:hidden;
	border-radius: 8px;
	z-index: 90000 !important;
}

.forum-title-toolbar {
	position: relative;
	top: 0px;
	right: 0px;
	overflow: hidden;
}

.form-select-topic {
	position: relative;

}

.module-head-right .select-text {
	width: 102px;
	margin-right: 4px;
}



/* Tabs	*/
.tabs {
	position: fixed;
	margin-bottom: 12px;
	top: 109px;
	left: var(--module-left);
	right: 8px;
	width: auto;
	height: 26px;
}

.select-layout-setting {
	position: static;
	width: 32px;
	overflow: hidden;
}

.input-select .bi-gear-wide {
	background-color: transparent;
}

@media screen and (max-width:1024px) {
	.tabs{
		left: 8px;
	}

	.layout-icon {
		visibility: hidden;
	} 
	.forum-icon {
		left: calc(var(--module-left) + 210px);
	}
	.forum-icon-count {
		left: calc(var(--module-left) + 216px);
	}
}
