.dialog-posting-day {
	position: fixed;
    top: 152px;
	right: 26px;
	height: 340px;
	width: 290px;
	min-width: 290px;
	height: auto;
}

.div-posting-day {
	overflow: hidden;
    width: 100%;
    height: auto;
}

