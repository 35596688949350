.webapp-module .module-content{
	z-index: 1;
	left: var(--module-left);
	top: 106px;
	bottom: 4px;
	right: 10px;
	justify-content: left !important;
	overflow: auto;

}

.module-head {
	position: fixed;
	border-bottom: solid var(--module-title-border) 1px;
	background-color: var(--content-bg-color);
	color: var(--content-color);
	top: 60px;
	left: var(--module-left);
	right: 8px;
	height: 38px;
	display: flex;
}

.module-head-title {
	position: absolute;
	top: 0px;
	left: 2px;
}

.module-head-right{
	position: absolute;
	top: 2px;
	right: 0px;
	color: var(--main-bg-color);
}

.module-head-right .bi:nth-child(1) {
	position: absolute;
	right: 0px;
}

.module-head-right span:nth-child(2) {
	position: absolute;
	right: 28px;
}

.module-title-toolbar{
	position: absolute;
	top: 0px;
	right: 34px;
}

.module-title-toolbar-2-iconss{
	position: relative;
	right: 28px;
	height: 30px;
	display: grid;
	grid-template-columns: 26px;
}

.module-title {
	cursor: pointer;
	color: black;
}

.module-title .bi-window-stack {
	color: var(--icon-color);
}

.module-head-title .select-text {
	width: 300px;
}

.window-list-icon {
	color: var(--main-bg-color);
	margin-left: 6px;
	padding-top: 2px;
}


.module-content {
	position: fixed;
	overflow: auto;
}

.module-frame{
	left: var(--module-left);
	top: 94px;
	bottom: 64px;
	right: 10px;
	z-index: 1;
}

.module-icon {
	margin: 0px 4px;
	font-size: 20px;
	color: var(--icon-color);
	cursor: pointer;
}

.mobile-icon {
	display: none;
}

.desktop-icon {
	display: initial;
}

.module-icon-clicked {
	color: var(--icon-color-clicked);
}

.module-icon-disabled {
	opacity: 0.3;
	cursor: auto;
}

.new-postings-icon {
	position: fixed;
	top: 103px;
	right: 204px;
	margin-bottom: 6px;
	padding-bottom: 16px;
}

#settings {
	padding-right: 8px;
}

.settings-form {
	min-width: 300px;
}

.module-toolbar{
	position: fixed;
	top: 62px;
	right: 36px;
	color: var(--main-bg-color);
	cursor: pointer;
}


@media screen and (max-width:1024px) {

	.module-head-title .select-text {
		width: 200px;
	}
	
	.module-head-right .select-text {
		width: 150px;
	}

	.desktop-icon {
		display: none;
	}

	.mobile-icon {
		display: initial;
	}

}