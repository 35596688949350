.dialog-postings-list {
	position: fixed;
    top: 152px;
	right: 26px;
	bottom: 64px;
	width: 40%;
	height: auto;
}

.dialog-postings-list .select-text{
	width: 300px;
}

.div-postings-list {
	overflow: hidden;
    width: 100%;
    height: auto;
}

.div-postings-list .bi-copy {
	margin-left: 6px;
	color: var(--main-bg-color);
}

.div-postings-list .member-name {
	margin-right: 6px;
}

.div-postings-list .posting {
	display: grid;
    grid-template-columns: 166px auto 32px;
}

.div-postings-list .posting-with-avatar {
	display: grid;
    grid-template-columns: auto 32px;
}

.div-postings-list .posting-date {
	color: var(--main-bg-color);
	padding: 0px;
}

.dialog-postings-list .dialog-content{
	height: calc(100% - 42px);
}

.dialog-postings-list .div-result {
	position: absolute;
	left: 8px;
	right: 2px;
	top: 2px;
	bottom: 0px;
	height: auto;
	overflow-x: auto;

}

.dialog-posting-member .dialog-title{
	height: 54px;
}

.dialog-posting-member .title{
	display: grid;
    grid-template-columns: 120px 200px;
	width: 100%;
	min-height: 54px;
	top: 4px;
}

.dialog-posting-member .title-static{
	top: 22px;
}

.dialog-posting-member span {
	position: relative;
}

.dialog-posting-member .select-text {
	position: relative;
	height: 38px;
	max-width: 320px;
	padding-right: 38px;
	top: 6px;
}

 .div-result {
	position: absolute;
	left: 8px;
	right: 2px;
	top: 2px;
	bottom: 0px;
	height: auto;
	overflow-x: auto;

}

.result-row {
	margin: 6px 0px;
	cursor: pointer;
}


.posting-count {
	margin-top: 12px;
	color: var(--main-bg-color);
}

.posting-list-content, .posting-list-content .posting-content {
	cursor: pointer;
}

@media screen and (max-width:720px) {
	.dialog-postings-list {
		right: 14px;
		left: 88px;
		width: auto;
		background-color: white;
	}
}