.module-title-toolbar-topics {
	position: relative;
	right: 28px;
	height: 30px;
	display: grid;
	grid-template-columns: 26px;
}


.module-page-store .pages-table .table-row, .module-page-store .pages-table .table-header-row {
	display: grid;
    grid-template-columns: 200px 200px auto 40px 70px 60px 40px;

}


.dialog-new-site textarea{
	height: 240px;
}

@media screen and (max-width:1024px) {
	.module-page-store .pages-table .table-row, .module-page-store .pages-table .table-header-row {
    	grid-template-columns: auto 40px 70px 60px 40px;
	}
	.module-page-store .pages-table .column-2,  .module-page-store .pages-table .header-column-2 {
		display: none;
	}
	.module-page-store .pages-table .column-3,  .module-page-store .pages-table .header-column-3 {
		display: none;
	}
}
