.navigation {
	background-color: var(--main-bg-color);
	color: #fefefe;
}
.navigation-top {
	background-color: var(--nav-bg-color);
	color: var(--nav-color);
    position: fixed;
    top: 0px;
    left: 270px;
    right: 0px;;
    height: 54px;
	overflow: hidden;
	border-bottom: 1px solid var(--nav-border-color);
}

.navigation-top-expand {
    height: 354px;
}

.navigation-top .bi-chat-left-text, .navigation-top .bi-house-fill, .navigation-top .bi-house, .navigation-top .bi-list {
    position: absolute;
    top: 14px;

}

.navigation-top .bi-list{
    right: 8px;

}

.navigation-top .bi-house-fill, .navigation-top .bi-house {
	left: 8px;
}

.new-postings-icon-count {
	position:fixed;
	top: 18px;
	left: calc(var(--module-left));
	margin-left: 2px;
	width: 16px;
	height: 16px;
	font-size: 10px;
	color: #fe0000;
	align-items: center;
	text-align: center;
	vertical-align: middle;
	cursor: pointer;;
}


.navigation-top .bi-chat-left-text {
    left: 36px;
	top: 18px;
	font-size: 18px;
}

.navigation-icon {
	cursor: pointer;
    line-height: 100%;
	font-size: 24px;
	color: var(--nav-icon-color);
}

@media screen and (max-width:1024px) {
	.navigation-top {
		position: fixed;
		top: 0px;
		left: 0px;
		right: 0px;;
		height: 54px;
		border-top-left-radius: 4px;
		border-top-right-radius: 4px;
	}
}
