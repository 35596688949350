.dialog-form {
	position: relative;
	top: 100px;
	z-index: 9999;
	width: 380px;
	margin:auto;
}

.dialog-form button {
	margin-right: 6px;
}

@media screen and (max-width:1024px) {
	.dialog-form {
		top: 8px;
		left: 0px;
		max-width: 380px;
	}
}