.active-member-list{
    position: relative;
	cursor: pointer;
    left: 0px;
    width: 270px;
    top: 0px;
	height: 100%;
	background-color: var(--listing-nav-bg-color);
	color: var(--listing-nav-color);
    bottom: 0px;
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;	
}
.active-member-frame {
	display: block;
	overflow: scroll;
}

.active-member-list .listing-content{
	color: var(--listing-nav-color);
	height: calc(100% - 63px);
	margin: 4px 0px 10px 0px;
}

.active-member-list .listing-row {
	border: none;
}
  
.active-member-list .listing-header-row {
	min-height: 54px;
	background-color: var(--listing-nav-bg-color);
	justify-content: left;
	border-bottom: 1px solid var(--listing-nav-border-color);
}

.active-member-list .listing-header-row .webapp-avatar{
	cursor: pointer;
	grid-template-columns: 44px 0px 120px;
}

.active-member-list .listing-footer {
	visibility: hidden;
	min-height: 55px;
	background-color: var(--listing-nav-bg-color);
	justify-content: left;
	border-top: 1px solid #5f5f5f;
	padding: 6px 0px 6px 0px;
}

.active-member-slist .listing-row:hover {
	background-color: #2f353b !important;
}



.active-member-list .column {
	border: none;
}
.active-member-list .column-1 {
	display: grid;
    grid-template-columns: 224px 46px;
	align-items: center;	
	width: 270px;
	overflow: hidden;

}

.active-member-list .forum-name {
	display: block;
	width: 100%;
	color: white;
}

.no-user-forum-icon {
	font-size: 22px;
	color: white;
	margin: 0px 14px 0px 0px;
}	

.listing-row-no-user-forum .column-1{
	display: grid;
    grid-template-columns: 30px 194px auto;
}

.listing-row-no-user-forum {
	height: 32px;
}

.active-member-list .unread-count {
	display: grid;
	align-items: center;
	justify-content: center;
	right: 28px;
	margin-top: 6px;
	font-size: 8px;
	font-weight: bold;
	color: white;
	background-color: var(--main-bg-color);
	text-align: center;
	vertical-align: middle;
	width: 18px;
	height: 18px;
	border-radius: 50%;
}

.active-member-list .bi-house {
	right: 38px;
}

.active-member-list .bi-house-check {
	right: 88px;
}

.active-member-list .bi-window-stack {
	right: 62px;
}

.active-member-list .bi-box-arrow-right {
	right: 12px;
}

.active-member-list .bi-circle-fill {
	font-size: 14px;
	color: #21b576
}

.active-member-list .bi-circle {
	font-size: 14px;
	color: #8f8f8f;
}

.active-member-list .bi-circle-fill .online {
	color: #148660;
}

.active-member-list .avatar-name {
	width: 226px;
}

.active-member-list .avatar-list-name {
	display: grid;
    grid-template-columns: 14px auto;
	align-items: center;			/* Vertical */
	width: 100%;
	height: 100%;
}

.active-member-list .avatar-name .member-name {
	display: block;
	color: #dfdfdf;
	margin-left: 8px;
	font-weight: normal;
	width: 100%;
	height: 100%;
}

.active-member-list .avatar-name .member-name:hover {
	font-weight: bold;
}

.active-member-list .listing-header-row .avatar-name {
	padding: 0px;
	width: 100%;
}

.active-member-list .online-count {
	position: absolute;
    top: 18px;
	left: 58px;
	color: #dfdfdf;
}
