.posting-context-menu {
	background-color: var(--content-bg-color);
	color: var(--content-color);
    margin: 2px 4px;
    height: 28px;
    width: auto;
    border-bottom: var(--main-bg-color) solid 1px;
	border: none;
}

.context-menu-title {
	color: var(--dialog-title-color);
}
.posting-context-menu span {
	margin-right: 4px;
}

.context-menu-icon {
	font-size: 18px;
	margin: 0px 3px;
	color: var(--main-bg-color);
}

@media screen and (max-width:1024px) {
	.tabs{
		left: 8px;
	}
}