.dialog-new-image {
	position: fixed;
    top: 152px;
	right: 14px;
	height: 440px;
	width: 620px;
	min-width: 290px;
}

.div-new-image img {
	max-height: 312px;
	max-width: 6120px;
}

.dialog-new-image label {
	margin-left: 4px;
} 

@media screen and (max-width:720px) {
	.dialog-new-image {
		top: 152px;
		right: 14px;
		height: 340px;
		width: 240px;
		min-width: 290px;
		}
}