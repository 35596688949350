.settings {
	max-width: 440px;
}

.settings .mb-2{
	margin-bottom: 1px !important;
}

.settings .avatar-fieldset .bi {
	display: inline-block;
	height: 32px;
	width: 32px;
	color: var(--icon-color);
	border: 1px solid #dfdfdf;
	border-radius: 4px;
	padding-bottom: 10px;
	padding: 0px 0px 0px 4px;
	margin: 6px 4px 0px 0px;
}

.settings .bi-download {
	margin: 6px 92px 0px 0px;
}

.settings .avatar-hint {
	display: block;
	margin: 12px 0px 0px 0px;
}

.settings .avatar-hint ul{
	margin-top: 6px;
}

.settings fieldset {
	border: none;
	border-top: 1px solid var(--main-bg-color);
	border-radius: 0 !important;
	margin: 0px 0px 0px 0px;
}

.settings legend {
	padding: 0px 8px;
	font-size: 24px;
}

.member-form .form-label {
	margin: 0px !important;
}

.member-form fieldset .form-label {
	width: 160px;
}

.member-form .form-buttons {
	margin-top: 12px;
}

.member-form .member-id {
	width: auto !important;
}

.member-form #postingCount {
	width: 80px;
}

.avatar {
	position: relative;
	top: 0px;
	left: 0px;
	width: 126px;
	height: 126px;
	max-height: none;
	background-color: transparent;
	margin-right: 8px;
	border: 1px solid #dfdfdf;
	border-radius: 4px;
}

.new-avatar {
	position: absolute;
	top: 0px;
	left: 0px;
	width: 124px;
	height: 124px;
	max-height: none;
	background-color: transparent;
}

