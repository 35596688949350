.system-info, textarea {
	max-width: initial;

}

.system-info textarea {
	position: absolute;
	top: 34px;
	left: 4px;
	right: 4px;
	bottom: 50px;
	height: auto;
	width: auto;
}

.system-info button {
	position: absolute;
	left: 4px;
	bottom: 0px;
}
