.webapp-modal {
	position: absolute;
	top: 0px;
	left: 0px;
	right: 0px;
	bottom: 0px;
	background-color: black;
	opacity: 0.2;
	z-index: 9000;
	outline: 0;
}
